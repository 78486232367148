'use client';

import ClassicLoader from '@/components/common/classic-loader';
import { Button, type ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { IconTrash } from '@tabler/icons-react';
import { useFormStatus } from 'react-dom';

type ClearCartButtonProps = ButtonProps &
  React.RefAttributes<HTMLButtonElement>;

export const ClearCartButton = (props: ClearCartButtonProps) => {
  const { pending } = useFormStatus();

  return (
    <Button
      type='submit'
      size='sm'
      disabled={props.disabled || pending}
      className={cn(props.className)}
      {...props}
    >
      {props.children}
      {!pending && (
        <IconTrash stroke={1.25} className={cn(props.children && 'ml-2')} />
      )}
      {pending && (
        <ClassicLoader
          className={cn(props.children && 'ml-3', 'h-4 w-4 animate-spin')}
        />
      )}
    </Button>
  );
};
