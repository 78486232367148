'use client';

import { useCallback, useEffect, useState } from 'react';

interface IMessage {
  type?: string;
  data?: any; // Changed from 'object' to 'any' for more flexibility
}

type MessageHandler = (message: IMessage) => void;

export const useNativeBridge = () => {
  let isNativeBridgeEnabled = false;
  let isNativeBridgeDebugEnabled = false;
  if (typeof window !== 'undefined') {
    isNativeBridgeEnabled = window.foodango_isMobileApp ?? false;
    isNativeBridgeDebugEnabled =
      window.foodango_isNativeBridgeDebugEnabled ?? false;
  }

  const [handlers, setHandlers] = useState<MessageHandler[]>([]);

  useEffect(() => {
    const meesageHandler = (event: MessageEvent) => {
      const message = event.data;

      if (isNativeBridgeDebugEnabled) {
        console.log('#NativeBridgeInfo: message handler:', message);
      }
      let finalData: any;
      let parsedMessage: IMessage = {};
      try {
        parsedMessage = JSON.parse(message);
        finalData = parsedMessage.data;
      } catch {
        finalData = message;
      }
      if (isNativeBridgeDebugEnabled) {
        console.log(
          '#NativeBridgeInfo: message handler - sending data to registered handlers:',
          {
            ...parsedMessage,
            data: finalData,
          },
        );
      }
      handlers.forEach((handler) => {
        handler({ ...parsedMessage, data: finalData });
      });
    };

    if (isNativeBridgeEnabled) {
      if (isNativeBridgeDebugEnabled) {
        console.log(
          '#NativeBridgeInfo: nativeBridge is enabled - Adding listener to document',
        );
      }
      document.addEventListener('message', meesageHandler as EventListener);
    }
    return () => {
      if (isNativeBridgeEnabled) {
        if (isNativeBridgeDebugEnabled) {
          console.log(
            '#NativeBridgeInfo: nativeBridge is enabled - Removing listener from document',
          );
        }
        document.removeEventListener(
          'message',
          meesageHandler as EventListener,
        );
      }
    };
  }, [handlers, isNativeBridgeEnabled]);

  const addNativeEventListener = useCallback(
    (onMessageHandler: MessageHandler) => {
      if (isNativeBridgeDebugEnabled) {
        console.log('#NativeBridgeInfo: addNativeEventListener');
      }
      if (typeof onMessageHandler === 'function') {
        setHandlers((prevHandlers) => [...prevHandlers, onMessageHandler]);
      }
    },
    [],
  );

  const postMessageToNative = useCallback((message: string | object) => {
    if (isNativeBridgeDebugEnabled) {
      console.log('#NativeBridgeInfo: postMessageToNative()', message);
    }
    if (isNativeBridgeEnabled && window.foodango_nativeBridge) {
      const messageString =
        typeof message === 'string' ? message : JSON.stringify(message);
      window.foodango_nativeBridge.postMessage(messageString);
      if (isNativeBridgeDebugEnabled) {
        console.log('#NativeBridgeInfo: postMessageToNative() sent', message);
      }
    }
  }, []);

  return {
    postMessageToNative,
    addNativeEventListener,
    isNativeBridgeEnabled,
    isNativeBridgeDebugEnabled,
  };
};

export default useNativeBridge;
