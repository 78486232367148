'use client';
import Trash from '@/assets/icons/trash.svg';
import ClassicLoader from '@/components/common/classic-loader';
import { Button } from '@/components/ui/button';
import { useTranslations } from 'next-intl';
import { useFormStatus } from 'react-dom';

export const RemoveFromCartButton = () => {
  const t = useTranslations();
  const { pending } = useFormStatus();

  return (
    <>
      {/* mobile */}
      <Button
        type='submit'
        variant='outline'
        disabled={pending}
        className='h-12 w-12 md:hidden'
        size='icon'
      >
        <span className='max-md:hidden'>{t('cart.remove')}</span>
        {!pending && <Trash className='h-6 w-6 md:ml-2' />}
        {pending && <ClassicLoader className='h-6 w-6 animate-spin md:ml-2' />}
      </Button>
      {/* desktop */}
      <Button
        type='submit'
        variant='outline'
        disabled={pending}
        className='hidden md:flex md:w-full'
        data-pw='remove-diet-button'
      >
        <span>{t('cart.remove')}</span>
        {pending && <ClassicLoader className='h-4 w-4 animate-spin md:ml-2' />}
      </Button>
    </>
  );
};
