'use client';

import { type FiltersStore, createFiltersStore } from '@/services/stores';
import { type ReactNode, createContext, useContext, useRef } from 'react';
import { type StoreApi, useStore } from 'zustand';

export const FiltersStoreContext = createContext<StoreApi<FiltersStore> | null>(
  null,
);

export interface FiltersStoreProviderProps {
  children: ReactNode;
  storageKey?: string;
}

export const FiltersStoreProvider = ({
  children,
  storageKey,
}: FiltersStoreProviderProps) => {
  const storeRef = useRef<StoreApi<FiltersStore>>(undefined);
  if (!storeRef.current) {
    storeRef.current = createFiltersStore({ storageKey });
  }

  return (
    <FiltersStoreContext.Provider value={storeRef.current}>
      {children}
    </FiltersStoreContext.Provider>
  );
};

export const useFiltersStore = <T,>(
  selector: (store: FiltersStore) => T,
): T => {
  const filtersStoreContext = useContext(FiltersStoreContext);

  if (!filtersStoreContext) {
    throw new Error(`useFiltersStore must be use within filtersStoreProvider`);
  }

  return useStore(filtersStoreContext, selector);
};
