import { removeFilter, replaceFilter } from './methods';
import {
  type FilterActions,
  type FilterType,
} from '@/components/filters/types';
import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

/**
 * Represents the state of filters.
 */
type FiltersState = {
  filters: FilterType[];
};

/**
 * Defines the actions available for managing filters.
 */
type FiltersActions = {
  /**
   * Changes the value of a filter.
   * @param filter - The filter to change.
   */
  changeFilter: (filter: FilterType) => void;

  /**
   * Resets all filters to their default values.
   */
  resetFilters: () => void;

  /**
   * Retrieves the current state of a filter by its name.
   * @param name - The name of the filter.
   * @returns The current state of the filter.
   */
  getFilterValueByName: (name: string) => string | string[] | undefined;

  /**
   * Removes a filter from the list of filters.
   * @param name - The name of the filter to remove.
   */
  removeFilterByName: (name: string) => void;

  /**
   * Get simplified state of the filters as a one-dimensional object.
   */
  getFilters: () => Record<string, string | string[]>;

  /**
   * Submits the filters to the parent component.
   */
  getSubmittableFilters: () => string;

  /**
   * Handle change of filter value.
   */
  handleChange: (
    filter: FilterType & FilterActions,
  ) => (name: string, value: string) => void;
};

/**
 * Represents a store that combines the state and actions related to filters.
 * @typedef {FiltersState & FiltersActions} FiltersStore
 */
type FiltersStore = FiltersState & FiltersActions;

/**
 * The default initial state for the filters.
 */
const defaultInitState: FiltersState = {
  filters: [],
};

/**
 * Creates a filters store using Zustand. The store is persisted to local storage.
 *
 * @param initState - The initial state of the filters store.
 * @returns The filters store object with state and actions.
 */
const createFiltersStore = ({
  initState = defaultInitState,
  storageKey = 'filters-storage',
}: {
  initState?: FiltersState;
  storageKey?: string;
}) => {
  return createStore<FiltersStore>()(
    persist(
      (set, get) => ({
        ...initState,

        changeFilter: (filter) =>
          set((state) => ({
            filters: replaceFilter(state.filters, filter),
          })),

        resetFilters: () => set(initState),

        getFilterValueByName: (name) => {
          const filter = get().filters.find((f) => f.name === name);
          return filter?.value;
        },

        removeFilterByName: (name) =>
          set((state) => ({ filters: removeFilter(state.filters, name) })),

        getFilters: () => {
          const filters = get().filters.reduce(
            (acc, filter) => ({ ...acc, [filter.name]: filter.value }),
            {},
          );
          return filters;
        },

        getSubmittableFilters: () => {
          const jsonFilters = get().getFilters();

          // parse the jsonFilters object to a querystring
          // examples:
          // { name: 'test', age: 20 } => 'name=test&age=20'
          // { name: 'test', age: [20, 30] } => 'name=test&age=20&age=30'
          let query: string[] = [];
          for (let key in jsonFilters) {
            if (jsonFilters[key] !== undefined) {
              const value = jsonFilters[key];
              if (Array.isArray(value)) {
                value.forEach((v) => {
                  query.push(`${key}=${v}`);
                });
              } else {
                query.push(`${key}=${value}`);
              }
            }
          }

          // It should be encode as below, but at the time API doesn't accept it
          // https://caterings.atlassian.net/browse/MPL-56
          // return encodeURIComponent(query.join('&'));

          return query.join('&');
        },

        handleChange: (filter) => (name, value) => {
          let label = filter.label;

          // Add a label suffix for range filters.
          if (filter.type === 'range') {
            if (name.replace(filter.name, '') === 'From')
              label = label + ' (above)';
            if (name.replace(filter.name, '') === 'To')
              label = label + ' (below)';
          }

          const formatValue = value.replace(/^0+/, '');
          get().changeFilter({
            ...filter,
            name: name,
            label: label,
            value: formatValue ?? undefined,
          });
        },
      }),
      {
        // The key to use for storing the state in local storage.
        name: storageKey,
      },
    ),
  );
};

export { createFiltersStore, type FiltersStore };
