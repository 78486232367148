import { toast } from '@/components/ui/sonner';
import { useMutation } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';

export const useSubscribe = () => {
  const t = useTranslations('newsletter.subscribe');
  return useMutation({
    mutationFn: async ({
      email,
      scenario,
      cookie,
    }: {
      email: string;
      scenario: string;
      cookie: string;
    }) => {
      const response = await fetch('/api/newsletter/subscribe', {
        method: 'POST',
        body: JSON.stringify({ email, scenario, cookie }),
      });

      if (!response.ok) {
        throw new Error(t('error'));
      }

      return response.json();
    },

    onSuccess: () => {
      toast.success(t('success'));
    },
    onError: () => {
      toast.error(t('error'));
    },
  });
};
