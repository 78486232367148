export const variants = {
  green: {
    bg: 'bg-brand-green',
    text: 'text-white',
    buttonBg: 'bg-white hover:bg-white/80',
    buttonText: 'text-brand-green',
  },
  violet: {
    bg: 'bg-brand-violet',
    text: 'text-white',
    buttonBg: 'bg-white hover:bg-white/80',
    buttonText: 'text-brand-violet',
  },
  blue: {
    bg: 'bg-brand-blue',
    text: 'text-white',
    buttonBg: 'bg-white hover:bg-white/80',
    buttonText: 'text-brand-blue',
  },
  red: {
    bg: 'bg-brand-red',
    text: 'text-white',
    buttonBg: 'bg-white hover:bg-white/80',
    buttonText: 'text-brand-red',
  },
  brown: {
    bg: 'bg-brand-brown',
    text: 'text-white',
    buttonBg: 'bg-white hover:bg-white/80',
    buttonText: 'text-brand-brown',
  },
  'light-green': {
    bg: 'bg-brand-green-light2',
    text: 'text-brand-green',
    buttonBg: 'bg-brand-green hover:bg-brand-green/80',
    buttonText: 'text-white',
  },
  'light-violet': {
    bg: 'bg-brand-violet-light',
    text: 'text-brand-violet',
    buttonBg: 'bg-brand-violet hover:bg-brand-violet/80',
    buttonText: 'text-white',
  },
  'light-blue': {
    bg: 'bg-brand-blue-light',
    text: 'text-brand-blue',
    buttonBg: 'bg-brand-blue hover:bg-brand-blue/80',
    buttonText: 'text-white',
  },
  'light-red': {
    bg: 'bg-brand-red-light',
    text: 'text-brand-red',
    buttonBg: 'bg-brand-red hover:bg-brand-red/80',
    buttonText: 'text-white',
  },
  'light-brown': {
    bg: 'bg-brand-brown-light',
    text: 'text-brand-brown',
    buttonBg: 'bg-brand-brown hover:bg-brand-brown/80',
    buttonText: 'text-white',
  },
};
