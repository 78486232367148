import { type TopBannerType } from '../types/top-banners.types';
import { useState } from 'react';

export const useTopBanner = (banner: TopBannerType) => {
  const [show, setShow] = useState(() => {
    if (typeof window === 'undefined') return true;

    const existingClosed = JSON.parse(sessionStorage.getItem('tb') || '[]');
    const storedBanners = JSON.parse(sessionStorage.getItem('tbData') || '{}');
    const uuid = banner.attributes.uuid;

    // If banner was previously closed but text has changed, show it again
    if (existingClosed.includes(uuid)) {
      const storedText = storedBanners[uuid];
      if (storedText !== banner.attributes.text) {
        // Text changed - remove UUID from closed banners and stored text
        const updatedClosed = existingClosed.filter(
          (id: string) => id !== uuid,
        );
        delete storedBanners[uuid];

        sessionStorage.setItem('tb', JSON.stringify(updatedClosed));
        sessionStorage.setItem('tbData', JSON.stringify(storedBanners));
        return true;
      }
      return false;
    }

    return true;
  });

  const handleClose = (uuid: string, text: string) => {
    setShow(false);
    if (typeof window !== 'undefined') {
      const existingClosed = JSON.parse(sessionStorage.getItem('tb') || '[]');
      const storedBanners = JSON.parse(
        sessionStorage.getItem('tbData') || '{}',
      );

      if (storedBanners[uuid] && storedBanners[uuid] !== text) {
        // Text changed - remove UUID from closed banners and stored text
        const updatedClosed = existingClosed.filter(
          (id: string) => id !== uuid,
        );
        delete storedBanners[uuid];

        sessionStorage.setItem('tb', JSON.stringify(updatedClosed));
        sessionStorage.setItem('tbData', JSON.stringify(storedBanners));
      }

      storedBanners[uuid] = text;
      sessionStorage.setItem('tbData', JSON.stringify(storedBanners));

      if (!existingClosed.includes(uuid)) {
        existingClosed.push(uuid);
        sessionStorage.setItem('tb', JSON.stringify(existingClosed));
      }
    }
  };

  return { show, handleClose };
};
