'use client';

import { ClearCartButton } from './clear-cart-button';
import { toast } from '@/components/ui/sonner';
import { useEventCart } from '@/features/analytics/hooks/use-event-cart';
import { useCartStore } from '@/features/cart/components/cart-provider';
import { type CartStore } from '@/features/cart/store/cart-store';
import { type Cart } from '@/features/cart/types/cart.type';
import { useTranslations } from 'next-intl';

export const ClearCartForm = ({
  afterSubmit,
}: {
  afterSubmit?: () => void;
}) => {
  const t = useTranslations();
  const cartStore: CartStore = useCartStore((state) => state);
  const { sendCertEvent } = useEventCart();

  const handleClearCart = async () => {
    if (!cartStore.cartId) return;

    const removedItems = cartStore.items.map((item) => item.configurationItem);

    await fetch('/api/cart/clear', {
      method: 'POST',
      body: JSON.stringify({ cartId: cartStore.cartId }),
    })
      .then(async (res: Response) => {
        if (!res.ok) {
          throw new Error('Failed to create cart');
        }
        const response = res.json() as Promise<{
          cartId: string;
          cart: Cart;
        }>;

        await sendCertEvent({
          eventName: 'remove_from_cart',
          diets: removedItems,
          value: cartStore.pricing?.final.gross || 0,
          other: {
            cartId: cartStore.cartId,
          }
        });

        return response;
      })
      .then(() => {
        cartStore.resetCart();
        cartStore.clearDeliveryCost();
      })
      .then(() => afterSubmit?.())
      .catch((error: Error) => toast.error(error.message));
  };

  const isDisabled =
    !cartStore.cartId ||
    (!cartStore.items?.length && !cartStore.multidietItems?.length);

  return (
    <form action={handleClearCart}>
      <ClearCartButton
        disabled={isDisabled}
        variant='link'
        className='h-5 px-0'
      >
        {t('cart.clear')}
      </ClearCartButton>
    </form>
  );
};
