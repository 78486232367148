'use client';

import { type CartItem } from '../../types';
import { RemoveFromCartButton } from './remove-from-cart-button';
import { toast } from '@/components/ui/sonner';
import { useEventCart } from '@/features/analytics/hooks/use-event-cart';
import { useCartStore } from '@/features/cart/components/cart-provider';
import { type CartStore } from '@/features/cart/store/cart-store';
import { type Cart } from '@/features/cart/types/cart.type';
import { useTranslations } from 'next-intl';

export const RemoveFromCartForm = ({
  id,
  afterSubmit,
  className,
  type = 'diet',
}: {
  id?: string;
  afterSubmit?: () => void;
  className?: string;
  type?: 'multidiet' | 'diet';
}) => {
  const t = useTranslations();
  const cartStore: CartStore = useCartStore((state) => state);
  const { sendCertEvent } = useEventCart();

  const handleRemove = async () => {
    let removedItem = null;

    if (type === 'diet') {
      removedItem = cartStore.items.find(
        (item) => item.configurationItem.id === id,
      );
    }

    if (type === 'multidiet') {
      removedItem = cartStore.multidietItems.find(
        (item) => item.multidiet.id === id,
      );
    }

    await fetch('/api/cart/remove', {
      method: 'POST',
      body: JSON.stringify({ cartId: cartStore.cartId, itemId: id, type }),
    })
      .then(async (res: Response) => {
        if (!res.ok) {
          throw new Error('Failed to remove from cart');
        }
        const response = res.json() as Promise<{
          cartId: string;
          cart: Cart;
        }>;

        // TODO send event for multidiet
        if (removedItem && type === 'diet') {
          await sendCertEvent({
            eventName: 'remove_from_cart',
            diets: [(removedItem as CartItem).configurationItem],
            value: removedItem.pricing.final.gross,
            other: {
              cartId: cartStore.cartId
            }
          });
        }

        return response;
      })
      .then(({ cartId, cart }) => {
        cartStore.updateCart(cartId, cart, 'remove', t);
        if (id) cartStore.removeDeliveryCost(id);
      })
      .then(() => afterSubmit?.())
      .catch((error: Error) => toast.error(error.message));
  };

  return (
    <form action={handleRemove} className={className}>
      <RemoveFromCartButton />
    </form>
  );
};
