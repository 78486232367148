'use client';
import { useEffect, useRef } from 'react';
import { useGetTopBanners } from '../hooks/api/use-get-top-banners';
import useTopBannersStore from '../store/top-banners-store';
import { TopBannersItem } from './top-banners-item';

export const TopBanners = () => {
  const { data } = useGetTopBanners({});
  const setTotalHeight = useTopBannersStore((state) => state.setTotalHeight);
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const totalHeight = useTopBannersStore((state) => state.totalHeight);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const updateTotalHeight = () => {
    const totalHeight = itemRefs.current.reduce((sum, ref) => {
      const existingClosed = JSON.parse(sessionStorage.getItem('tb') || '[]');

      const uuid = ref.getAttribute('data-uuid');
      if (existingClosed.includes(uuid)) return sum;

      return sum + (ref?.getBoundingClientRect().height || 0);
    }, 0);

    setTotalHeight(totalHeight);
  };

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(updateTotalHeight);

    itemRefs.current.forEach((ref) => {
      if (ref) resizeObserverRef.current?.observe(ref);
    });

    updateTotalHeight();

    return () => {
      itemRefs.current.forEach((ref) => {
        if (ref) resizeObserverRef.current?.unobserve(ref);
      });
      resizeObserverRef.current?.disconnect();
    };
  }, [data, setTotalHeight]);

  const handleItemClose = (index: number) => {
    if (itemRefs.current[index]) {
      resizeObserverRef.current?.unobserve(itemRefs.current[index]);
    }

    itemRefs.current = itemRefs.current.filter((_, i) => i !== index);

    itemRefs.current.forEach((ref) => {
      if (ref) {
        resizeObserverRef.current?.observe(ref);
      }
    });

    if (itemRefs.current.length === 0) {
      setTotalHeight(0);
    } else {
      updateTotalHeight();
    }
  };

  return (
    <>
      <div
        className='h-0 transition-[height]'
        style={{ height: `${totalHeight}px` }}
      ></div>
      <div className='fixed top-0 z-20 w-full'>
        {data?.data?.map((banner, index) => (
          <TopBannersItem
            key={banner.attributes.uuid}
            ref={(el: HTMLDivElement | null) => {
              if (el) {
                itemRefs.current = itemRefs.current.filter(Boolean);
                itemRefs.current[index] = el;
              }
            }}
            banner={banner}
            onClose={() => handleItemClose(index)}
          />
        ))}
      </div>
    </>
  );
};
