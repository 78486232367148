import { type ConfigurationItem } from '../../cart/types';
import { sendEvent } from '../gtm/event';
import { type EventAddToCartType } from '../types/event.type';
import { getDiet } from '@/features/diets/api/get-diet';
import { type DietCatalogDetail } from '@/features/diets/types';

type AllowedEvents =
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'view_cart'
  | 'begin_checkout';

export const useEventCart = () => {
  const sendCertEvent = async ({
    eventName,
    diets,
    value,
    other,
  }: {
    eventName: AllowedEvents;
    diets: ConfigurationItem[];
    value: number;
    other?: Partial<EventAddToCartType['ecommerce']> & {
      coupon?: string;
    };
  }) => {
    const quueries: Promise<DietCatalogDetail>[] = [];
    const fetchData = async () => {
      diets.forEach((element) => {
        quueries.push(getDiet(element.dietId));
      });

      const results = await Promise.all(quueries);

      return results;
    };

    const fetchedDiets = await fetchData();

    await sendEvent({
      event: eventName,
      ecommerce: {
        currency: 'PLN',
        value: value,
        items: fetchedDiets.map((diet) => {
          return {
            item_id: diet?.id || '',
            item_name: diet?.name || '',
            item_brand: diet?.catering.name || '',
          };
        }),
        ...other,
      },
    });
  };

  return { sendCertEvent };
};
