'use client';

import useNativeBridge from './use-native-bridge';
import { useSignIn, useUser } from '@clerk/nextjs';
import { useEffect, useState } from 'react';

export const useNativeAppSetup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { addNativeEventListener, isNativeBridgeEnabled } = useNativeBridge();
  const { signIn, setActive } = useSignIn();
  const { isSignedIn, user } = useUser();

  useEffect(() => {
    if (isNativeBridgeEnabled) {
      if (isSignedIn) {
        window?.foodango_nativeBridge?.postMessage(
          JSON.stringify({ type: 'USER_SIGNED_IN', data: { userId: user.id } }),
        );
      } else {
        window?.foodango_nativeBridge?.postMessage(
          JSON.stringify({ type: 'USER_SIGNED_OUT' }),
        );
      }
    }
  }, [isSignedIn, user, isNativeBridgeEnabled]);

  useEffect(() => {
    if (isNativeBridgeEnabled && signIn && setActive) {
      const onMessage = async (message: any) => {
        const messageData = message['data'] as any;
        const messageType = messageData['type'];

        switch (messageType) {
          case 'GOOGLE_SIGN_IN': {
            setIsLoading(true);
            const clerkSignInToken = messageData['result']['clerkSignInToken'];

            try {
              const result = await signIn?.create({
                strategy: 'ticket',
                ticket: clerkSignInToken,
              });

              await setActive?.({
                session: result?.createdSessionId,
              });

              location.reload();
            } catch (error) {
              console.error(error);
              setError((error as Error).toString());
              window?.foodango_nativeBridge?.postMessage(
                JSON.stringify({ type: 'GOOGLE_SIGN_IN_HIDE_LOADER' }),
              );
            } finally {
              setIsLoading(false);
            }
            break;
          }
          default: {
            const message = `Message type not supported: ${messageType}`;
            console.warn(message);
            setError(message);
          }
        }
      };
      addNativeEventListener(onMessage);
    }
  }, [signIn, setActive, isNativeBridgeEnabled]);

  return {
    isLoading,
    error,
  };
};

export default useNativeAppSetup;
