import { create } from 'zustand';

interface AsPathStoreType {
  visitedPaths: string[];
  currentPath: string | undefined;
}

export const asPathStore = create<AsPathStoreType>(() => ({
  visitedPaths: [],
  currentPath: undefined,
}));

export const useAsPath = () => asPathStore((state) => state);

export const addToHistory = (path: string) => {
  const state = asPathStore.getState();

  const lastVisitedPath = state.visitedPaths[state.visitedPaths.length - 1];

  if (!state.currentPath) {
    asPathStore.setState({
      currentPath: path,
    });
  } else if (state.currentPath !== path && path !== lastVisitedPath) {
    asPathStore.setState({
      visitedPaths: [...state.visitedPaths, state.currentPath],
      currentPath: path,
    });
  }
};

export const getPreviousPath = (): string | undefined => {
  const state = asPathStore.getState();
  return state.visitedPaths[state.visitedPaths.length - 1];
};

export const clearHistory = () => {
  asPathStore.setState({
    visitedPaths: [],
    currentPath: undefined,
  });
};

export const removeLastPath = () => {
  const state = asPathStore.getState();
  const previousPath = state.visitedPaths[state.visitedPaths.length - 1];

  asPathStore.setState({
    visitedPaths: state.visitedPaths.slice(0, -1),
    currentPath: previousPath,
  });
};
