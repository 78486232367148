import { type TopBannerResponseType } from '../../types/top-banners.types';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

type UseTopBannersResuptsParams = {
  options?: Omit<
    UseQueryOptions<
      TopBannerResponseType,
      unknown,
      TopBannerResponseType,
      string[]
    >,
    'queryFn' | 'queryKey'
  >;
};

const useGetTopBanners = ({ options }: UseTopBannersResuptsParams) => {
  return useQuery({
    queryKey: ['top-banners'],
    queryFn: () =>
      fetch('/api/top-banners', {
        method: 'GET',
      }).then((res) => res.json()) as Promise<any>,
    ...options,
  });
};

export { useGetTopBanners };
