'use client';

import { variants } from '../data';
import { useTopBanner } from '../hooks/top-banner.hook';
import { type TopBannerType } from '../types/top-banners.types';
import CloseIcon from '@/assets/icons/close.svg';
import PercentageIcon from '@/assets/icons/percentage.svg';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import Link from 'next/link';
import { forwardRef } from 'react';

export const TopBannersItem = forwardRef(
  (
    { banner, onClose }: { banner: TopBannerType; onClose: () => void },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const variant = variants[banner.attributes.variant] || variants['green'];
    const { show, handleClose } = useTopBanner(banner);

    return (
      <div
        ref={ref}
        className={cn(
          variant.bg,
          'flex min-h-[52px] items-center py-2 transition-[height]',
          !show && 'hidden',
        )}
        data-uuid={banner.attributes.uuid}
      >
        <div
          className={cn(
            variant.text,
            'container relative mx-auto flex flex-wrap items-center gap-x-5 gap-y-1 font-medium md:justify-center',
          )}
        >
          <div className='flex items-center gap-x-2 md:gap-x-5'>
            {banner.attributes.display_icon && <PercentageIcon />}
            {banner.attributes.display_text &&
            banner.attributes.display_button ? (
              banner.attributes.text.slice(0, 40)
            ) : (
              <Link
                href={banner.attributes.button_url || '/'}
                className='underline underline-offset-2 hover:no-underline'
              >
                {banner.attributes.text.slice(0, 70)}
              </Link>
            )}
          </div>
          {banner.attributes.display_button && (
            <Link
              href={banner.attributes.button_url || '/'}
              className={cn(
                variant.buttonText,
                variant.buttonBg,

                'flex h-8 items-center gap-x-2 rounded-lg px-4 py-0 text-sm font-bold md:h-9',
              )}
            >
              {banner.attributes.button_text || ''}
              <IconArrowNarrowRight stroke={1.25} className='size-5' />
            </Link>
          )}
          {banner.attributes.display_close && (
            <Button
              variant='ghost'
              className='absolute right-0 top-1/2 min-h-9 w-9 -translate-y-1/2 p-0 text-white'
              onClick={() => {
                onClose();
                handleClose(banner.attributes.uuid, banner.attributes.text);
              }}
            >
              <CloseIcon className={variant.text} />
            </Button>
          )}
        </div>
      </div>
    );
  },
);
