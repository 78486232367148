import { type FilterType } from '@/components/filters/types';

/**
 * Handles a single value by comparing it with the current value and returning an updated value if they are different.
 * @param current The current value.
 * @param value The new value to be compared with the current value.
 * @returns The updated value if it is different from the current value, otherwise an empty string.
 */
const handleSingleValue = (
  current: string | string[],
  value: string | string[],
) => {
  if (current === value) return '';
  return value;
};

/**
 * Handles the changes of a multi-value field.
 * If the current value is an array, it appends or removes the given value.
 * If the current value is a string, it converts it to an array and performs the same operation.
 * If the given value is an array, it returns it as is.
 * If the given value is already present in the current value, it removes it.
 * If the given value is not present in the current value, it appends it.
 * @param current The current value of the field.
 * @param value The value to be appended or removed.
 * @returns The updated value of the field.
 */
const handleMultiValue = (
  current: string | string[],
  value: string | string[],
) => {
  const multiCurrent = Array.isArray(current) ? current : [current];
  if (Array.isArray(value)) return value;
  if (multiCurrent.includes(value))
    return multiCurrent.filter((v) => v !== value);
  return [...multiCurrent, value];
};

/**
 * Handles the value based on the filter type.
 * @param filter - The filter object.
 * @param newValue - The new value to be handled.
 * @returns The handled value.
 */
const handleValue = (filter: FilterType, newValue: string | string[]) => {
  switch (filter.type) {
    case 'single':
      return handleSingleValue(newValue, filter.value);
    case 'multi':
      return handleMultiValue(newValue, filter.value);
    default:
      return filter.value;
  }
};

/**
 * Replaces a filter in the state array with a new filter or adds it if it doesn't exist.
 * @param state - The current array of filters.
 * @param filter - The filter to replace or add.
 * @returns The updated array of filters.
 */
export const replaceFilter = (
  state: FilterType[],
  filter: FilterType,
): FilterType[] => {
  const index = state.findIndex((f) => f.name === filter.name);
  if (index === -1) {
    return [...state, filter];
  }

  return state
    .map((currentFilter, currentIndex) =>
      currentIndex === index
        ? {
            ...currentFilter,
            value: handleValue(filter, currentFilter.value),
          }
        : currentFilter,
    )
    .filter((filteredFilter) => filteredFilter.value?.length > 0);
};

/**
 * Removes a filter from the state array.
 * @param state - The current array of filters.
 * @param name - The name of the filter to remove.
 * @returns The updated array of filters.
 */
export const removeFilter = (
  state: FilterType[],
  name: string,
): FilterType[] => {
  return state.filter((filter) => filter.name !== name);
};
