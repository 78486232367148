'use client';

import { useCartStore } from './cart-provider';
import { type ButtonProps, buttonVariants } from '@/components/ui/button';
import { Link, getLocaleForPathname } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { IconShoppingBag } from '@tabler/icons-react';

type CartNavigationButtonProps = ButtonProps &
  React.RefAttributes<HTMLButtonElement>;

export const CartNavigationButton = ({
  className,
}: CartNavigationButtonProps) => {
  const cartStore = useCartStore((state) => state);
  const locale = getLocaleForPathname();
  return (
    <Link
      href='/cart'
      locale={locale}
      className={cn(
        buttonVariants({ variant: 'ghost' }),
        'flex items-center space-x-2',
        className,
      )}
    >
      <IconShoppingBag stroke={1.5} className='size-6' />
      <span>{cartStore?.items?.length}</span>
    </Link>
  );
};
