'use client';

import { addToHistory, removeLastPath, useAsPath } from '../store/path-store';
import { getPathname } from '@/i18n/navigation';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useRef } from 'react';

export const PathInitializer = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isFirstRender = useRef(true);
  const state = useAsPath();
  const visitedPaths = state.visitedPaths;

  const localizedPathname: string = getPathname({
    href: pathname,
    locale: 'pl',
  });

  useEffect(() => {
    const searchParamsString = searchParams?.toString();
    const fullPath = searchParamsString
      ? `${pathname}?${searchParamsString}`
      : pathname;
    if (pathname !== '/cart' && pathname !== '/cart/[id]') {
      if (localizedPathname === visitedPaths[visitedPaths.length - 1]) {
        removeLastPath();
      } else {
        if (!isFirstRender.current) {
          addToHistory(fullPath);
        } else {
          isFirstRender.current = false;
          addToHistory(fullPath);
        }
      }
    }
  }, [pathname, searchParams]);

  return null;
};
