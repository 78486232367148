import { create } from 'zustand';

type Store = {
  totalHeight: number;
};

type Actions = {
  setTotalHeight: (height: number) => void;
};

const useTopBannersStore = create<Store & Actions>()((set) => ({
  totalHeight: 0,
  setTotalHeight: (height) => set({ totalHeight: height }),
}));

export default useTopBannersStore;
