'use client';

import ArrowLink from '@/assets/icons/arrow-link.svg';
import ClassicLoader from '@/components/common/classic-loader';
import { FormInput } from '@/components/form';
import { FormCheckbox } from '@/components/form/form-checkbox';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useSubscribe } from '@/features/newsletter/hooks/use-subscribe';
import { Link } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const NewsletterForm = ({
  cookie,
  layout = 'full',
  className,
  scenario,
}: {
  cookie: string;
  layout?: 'inline' | 'full';
  className?: string;
  scenario: 'newsletter_homepage' | 'stopka_newsletter';
}) => {
  const t = useTranslations();
  const { mutate: subscribe } = useSubscribe();
  const [isLoading, setIsLoading] = useState(false);

  const FormSchema = z.object({
    email: z
      .string()
      .min(1, { message: 'validators.required' })
      .email('validators.email'),
    consent: z.boolean().refine((val) => val === true, {
      message: 'validators.required',
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      consent: false,
    },
  });

  const onSubmit = (data: z.infer<typeof FormSchema>) => {
    setIsLoading(true);
    subscribe(
      { email: data.email, scenario, cookie },
      {
        onSuccess: () => {
          setIsLoading(false);
          form.reset();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const newsletterFormLabel = (
    <Link
      href={{ pathname: '/privacy-policy' }}
      title={t('staticBuilder.consent')}
      className={cn(
        'text-primary/80 flex items-center space-x-0.5 underline hover:no-underline',
        className,
      )}
      target='_blank'
    >
      <span>{t('staticBuilder.consent')}</span>
      <ArrowLink />
    </Link>
  );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn(
          'text-brand-green1 mt-4 flex flex-wrap gap-3',
          layout === 'inline' && '[&_.text-destructive]:text-white',
        )}
      >
        <div
          className={cn('order-1 w-full grow md:w-auto', {
            relative: layout === 'inline',
          })}
        >
          <FormInput
            form={form}
            fieldName='email'
            fieldPlaceholder={t('staticBuilder.email_address')}
            layout='medium'
            inputClassName={cn(layout === 'inline' && 'pr-30')}
          />
          {layout === 'inline' && (
            <Button
              type='submit'
              className='bg-brand-green1 absolute right-0 top-0 h-14 px-5 text-base text-white md:order-2 md:w-auto'
              variant='default'
              disabled={isLoading}
            >
              {t('staticBuilder.subscribe')}
              {isLoading && (
                <ClassicLoader className='h-4 w-4 animate-spin md:ml-2' />
              )}
            </Button>
          )}
        </div>
        <div className='order-2 mb-3 mt-1 md:order-3 md:mb-0 md:mt-4'>
          <FormCheckbox
            form={form}
            fieldName='consent'
            fieldLabel={newsletterFormLabel}
            layout='bigWhite'
          />
        </div>
        {layout !== 'inline' && (
          <Button
            type='submit'
            className='order-3 h-14 w-full px-8 md:order-2 md:w-auto'
            variant='secondary'
            disabled={isLoading}
          >
            {t('staticBuilder.subscribe')}
            {isLoading && (
              <ClassicLoader className='h-4 w-4 animate-spin md:ml-2' />
            )}
          </Button>
        )}
      </form>
    </Form>
  );
};
